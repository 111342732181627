<template>
	<video ref="videoRef"
		   :poster="poster"
		   :disable-remote-playback="disableCastIcon"
		   :class="{ 'disable-cast-icon': disableCastIcon }">
		<template v-for="video in videofiles"
				  :key="video.file.data.attributes.url">
			<source :src="`${config.public.mediaBase}${video.file.data.attributes.url}`"
					:type="`${video.file.data.attributes.mime}`">
		</template>
		Your browser does not support HTML5 video.
	</video>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import debounceFn from 'debounce-fn'

if (process.client) gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
	data: {
		type: Object,
		required: true
	},
	dontPlay: {
		type: Boolean,
		default: false
	},
	disableCastIcon: {
		type: Boolean,
		default: null
	}
})

const config = useRuntimeConfig()
const { $bus } = useNuxtApp()
const poster = props.data?.poster?.data ? (config.public.mediaBase + '/uploads/f_webp/' + props.data?.poster?.data?.attributes?.formats?.medium.hash + props.data?.poster?.data?.attributes?.formats?.medium.ext) : null
const videoRef = ref(null)
const videoEl = computed(() => videoRef.value)

const videofiles = computed(() => {
	return sortedFiles()
})

// const disableRemotePlayback = computed(() => {
// 	if (props.disableCastIcon === true) return true
// 	return null
// })

function sortedFiles() {
	const files = props.data?.videofiles ? props.data.videofiles : props.data
	if (!files) return false


	// for some reason the below will sort properly on client but not when ssr'd
	const sortedFiles = files.slice().sort(function (a, b) {
		const typeA = a.file.data.attributes.ext.split('.').pop()
		const typeB = b.file.data.attributes.ext.split('.').pop()
		// Sort webm, mp4, ogv first 
		if (typeA === 'webm' && typeB !== 'webm') return -1
		if (typeB === 'webm' && typeA !== 'webm') return 1

		if (typeA === 'mp4' && typeB !== 'mp4') return -1
		if (typeB === 'mp4' && typeA !== 'mp4') return 1

		if (typeA === 'ogv' && typeB !== 'ogv') return -1
		if (typeB === 'ogv' && typeA !== 'ogv') return 1

		// Finally alphabetically 
		return a.localeCompare(b)
		// alternatively don't sort by file name and just return 0 here, only counting webm, mp4 and ogv.
		return 0
	})

	return sortedFiles
}

const debouncedCheckload = debounceFn(checkLoadStatus, { wait: 50, maxWait: 200 })
function checkLoadStatus() {
	if (videoEl.value && videoEl.value.readyState >= 3) {
		// console.log('video loaded', videoEl.value.readyState)
		// props.dontPlay && console.log('shouldnt play')
		!props.dontPlay && videoEl.value.play()
		$bus.$emit('scrolltrigger-refresh')
	} else {
		debouncedCheckload()
	}
}

onMounted(() => {
	nextTick(() => {
		debouncedCheckload()
	})
})
</script>

<style lang="scss"
	   scoped>
	video {
		width: 100%;
		height: auto;
	}

	// .disable-cast-icon::-internal-media-controls-overlay-cast-button {
	// 	display: none;
	// }
</style>